import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/veterans.scss"
// import ImageSlider from "../components/ImageSlider/ImageSlider"

const SmileForVeteransPage = () => {
  return (
    <Layout language="en">
      <SEO
        title="Smiles For Veterans Program"
        description="Smiles For Veterans Program at Muir Oral, Facial, & Dental Implant Surgery of Walnut Creek"
      />
      <div className="sfv">
        <div className="sfv__hero sfv__section">
          <img
            className="sfv__hero-img--desktop"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smiles-for-veterans-desktop-hero-image"
            alt="smiles for soliders banner"
          />
          <img
            className="sfv__hero-img--mobile"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smiles-for-veterans-mobile-hero-image"
            alt="smiles for soliders banner"
          />
        </div>

        <div className="sfv__body sfv__section">
          <div className="sfv__container small">
            <h1>SUPPORTING THOSE WHO SERVE</h1>

            <p>
              The team at Muir Oral, Facial, & Dental Implant Surgery Of Walnut
              Creek is proud to announce that this year’s Smiles For Veterans
              recipient is Deborah Gales from San Pablo, CA!
            </p>

            <p>
              Deborah Gales, 52, is a Navy veteran and station agent for BART.
              After experiencing homelessness and living in her car, Deborah has
              found a job and is working toward securing permanent housing.
              Despite her challenges, she remains hopeful and determined.
            </p>
            <p>
              Deborah shared that her ill-fitting dentures make it difficult to
              smile, eat, and speak in her customer service role, which has
              affected her confidence and well-being. Restoring her smile is
              important to her, as it would not only ease her daily struggles
              but also help her regain her self-esteem. She believes that a new
              smile would be a fresh start in rebuilding her confidence and
              outlook on life.
            </p>
            <p>
              We are honored to support Deborah in this next step toward a
              brighter future and a renewed sense of pride.
            </p>

            <h2 style={{ fontSize: "24px" }}>FULL-ARCH RESTORATION</h2>
            <p>
              Deborah will receive a full set of customized, artificial teeth at
              no cost. This tooth replacement solution is called full-arch
              restoration because it replaces a full dental arch of missing or
              failing teeth. Our team of experts will work closely with a
              restorative dentist to complete the treatment.
            </p>
          </div>
        </div>

        {/* <div className="sfv__section">
          <div className="sfv__container">
            <ImageSlider
              adaptiveHeight
              useDots
              images={[
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-1",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-2",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-3",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-4"
              ]}
            />
          </div>
        </div> */}

        <div className="sfv__section">
          <div className="sfv__container small">
            <p>
              Follow this page,{" "}
              <a
                href="https://www.facebook.com/Muir-Oral-Facial-Dental-Implant-Surgery-108645897698163/"
                title="Follow us on Facebook"
                target="_blank"
              >
                Facebook
              </a>
              , and{" "}
              <a
                href="https://www.instagram.com/muiroralsurgery/"
                title="Follow us on Instagram"
                target="_blank"
              >
                Instagram
              </a>{" "}
              to follow Deborah’s journeys to a new smile!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/dental-implants-walnut-creek-ca/"
                  title="Learn more about dental implants"
                >
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/full-arch-restoration-walnut-creek-ca/"
                  title="Learn more about full-arch-restoration"
                >
                  full-arch restoration
                </Link>{" "}
                to see how they can improve your oral health and confidence.
                Whether you need to replace one tooth or an entire arch of
                teeth, our team has a solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SmileForVeteransPage
